import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap-accordion';
import { useTranslation } from 'react-i18next';

AccordionItem.propTypes = {
    item: PropTypes.object,
};

function AccordionItem(props) {
    const {item} = props;

    const {t} = useTranslation()

    return (
        <Accordion key={item.id} title={t(item.title)} show={item.show}>
            <p className="toggle-content">{t(item.content)}</p>
        </Accordion>
    );
}

export default AccordionItem;