import img1 from '../images/post/post1.png'
import img2 from '../images/post/post2.png'
import img3 from '../images/post/post3.png'

const dataBlog = [
    {
        id: 1,
        img : img1,
        category: 'site',
        title: 'blog-1',
        text: "blog-1-advice",
        link: '/blog/web-sayt'
    },
    {
        id: 2,
        img : img2,
        category: "online-shop",
        title: 'blog-2',
        text: "blog-2-advice",
        link: '/blog/internetdokon'
    },
    {
        id: 3,
        img : img3,
        category: 'site',
        title: 'blog-3',
        text: "blog-3-advice",
        link: '/blog/turlari'
    },
]

export default dataBlog;