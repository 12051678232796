const dataRoadmap = [
    {
        id: 1,
        time: 'apply',
        list: [
            {
                text: 'ordering-1'
            },
        ],
        positon: 'left',
        style: 'normal'
    },
    {
        id: 2,
        time: "connect",
        list: [
            {
                text: "ordering-2"
            },
        ],
        positon: 'right',
        style: 'normal'
    },
    {
        id: 3,
        time: 'ordering-3',
        list: [
            {
                text: 'ordering-4'
            },
        ],
        positon: 'left'
    },
    {
        id: 4,
        time: 'price',
        list: [
            {
                text: "ordering-5"
            },
        ],
        positon: 'right'
    },
    {
        id: 5,
        time: "first-payment",
        list: [
            {
                text: "ordering-6"
            },
        ],
        positon: 'left'
    },
    {
        id: 6,
        time: 'ordering-7',
        list: [
            {
                text: 'ordering-8'
            },
        ],
        positon: 'right'
    },
    {
        id: 7,
        time: 'test',
        list: [
            {
                text: "ordering-9"
            },
        ],
        positon: 'left'
    },
    {
        id: 8,
        time: 'ending',
        list: [
            {
                text: "ordering-10"
            },
        ],
        positon: 'right'
    },
    {
        id: 9,
        time: "support",
        list: [
            {
                text: "ordering-11"
            },
        ],
        positon: 'left'
    },
]

export default dataRoadmap;