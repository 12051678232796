
import img1 from '../images/logo/logo-1.png'
import img2 from '../images/logo/logo-2.png'
import img3 from '../images/logo/logo-3.jpg'
import img4 from '../images/logo/logo-4.jpg'
import img5 from '../images/logo/dizi.png'
import img6 from '../images/logo/logo-6.jpg'
import img7 from '../images/logo/mashriq.png'
import img8 from '../images/logo/logo-8.jpg'
import img9 from '../images/logo/admin.png'
import img10 from '../images/logo/logo-10.png'
import img11 from '../images/logo/logo-11.png'
import img12 from '../images/logo/logo-12.jpg'
import img13 from '../images/logo/logo-13.png'
import img14 from '../images/logo/ali.png'
import img15 from '../images/logo/logo-15.png'
import img16 from '../images/logo/logo-16.jpg'
import img17 from '../images/logo/logo-17.jpg'
import img18 from '../images/logo/logo-18.png'
import img19 from '../images/logo/hotelus.png'
import img20 from '../images/logo/logo-20.jpg'
import img21 from '../images/logo/logo-21.jpg'
import img22 from '../images/logo/logo-22.png'
import img23 from '../images/logo/logo-23.png'
import img24 from '../images/logo/sis.jpg'

const dataPartner = [
    {
        img: img1,
    },
    {
        img: img2,
    },
    {
        img: img13,
    },
    {
        img: img10,
    },
    {
        img: img5,
    },
    {
        img: img15,
    },
    {
        img: img11,
    },
    {
        img: img14,
    },
    {
        img: img9,
    },
    {
        img: img22,
    },
    {
        img: img7,
    },
    {
        img: img23,
    },
    {
        img: img21,
    },
    {
        img: img19,
    },
    {
        img: img6,
    },
    {
        img: img16,
    },
    {
        img: img17,
    },
    {
        img: img3,
    },
    {
        img: img8,
    },
    {
        img: img20,
    },
    {
        img: img12,
    },
    {
        img: img4,
    },
    {
        img: img24,
    },
    {
        img: img18,
    },

]

export default dataPartner;