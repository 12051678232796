import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

RoadMapItem.propTypes = {
    item: PropTypes.object,
};



function RoadMapItem(props) {


    const {t} = useTranslation()
    const {item} = props;
    return (
        <div key={item.id} className={`rm-box ${item.positon}`} data-aos="zoom-in" data-aos-duration="1200">
            <div className={`corner-box ${item.style}`}>
                <h5>{t(item.time)}</h5>
                <ul>
                    {
                        item.list.map((li,idx) => (
                            <li key={idx}>{t(li.text)}</li>
                        ))
                    }
                </ul>
            </div>
        </div>
    );
}

export default RoadMapItem;