import React, {useState} from 'react';
import PageTitle from '../components/pagetitle';
import { useTranslation } from 'react-i18next';


function Contact(props) {
    const [name,setName] = useState("");
    const [phone,setPhone] = useState("")
    const [message,setMessage] = useState("");
    console.log(name, phone)
    function formHandler(e) {
        e.preventDefault()
        if(name && phone){
            console.log("sa;");
            const myText = `Ismi: ${ name } %0A Telefon Raqam: ${ phone }%0A Xabar: ${message}`
            const token = "5787667131:AAFoCfzFD4VtNn6N_jMtxOf3KKFMA23L3ho";
            const chatId = "-1001617379677"
            const url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&parse_mode=html&text=${myText}`
            console.log(url);
            const api = new XMLHttpRequest();
            api.open("Get", url, true);
            api.send() 
            setPhone("")
            setName("")
            setMessage("")
            alert("Arizangiz yuborildi, tez orada siz bilan bog'lanamiz")
        }
        else{
            alert('Error')
        }
    }

    const {t} = useTranslation()

    return (
        <div>
            {/* <PageTitle title='Contact Uss' /> */}

            <section id='aloqa'  className="tf-section tf-contact">
                <div id='aloqa' className="container">
                    <div className="row">
                        <div className="col-xl-5 col-md-12">
                            <div className="content-about m-b50 mobie-40" data-aos="fade-right" data-aos-duration="800">
                                <div className="tf-title st2 m-b17">
                                    <h4 className="title">{t("connecting")}</h4>
                                </div>
                                <p className="m-r-40">{t("connecting-desc")}</p>
                            </div>
                            <div  className="form-contact" id="contactform" data-aos="fade-right" data-aos-duration="800">
                                <fieldset>
                                    <input type="text" name="name" id="name" placeholder={t("name")} value={name} onChange={(e)=>[setName(e.target.value)]} />
                                </fieldset>
                                {/* <fieldset>
                                    <input type="email" name="mail" id="mail" placeholder="Email Address" />
                                </fieldset> */}
                                <fieldset>
                                    <input type="number" name="phone" id="phone" placeholder={t('phone')} value={phone} onChange={(e)=>[setPhone(e.target.value)]} />
                                </fieldset>
                                <fieldset>
                                    <textarea placeholder={t("desc")} rows="5" tabIndex="4" name="message" value={message} onChange={(e)=>[setMessage(e.target.value)]} className="message" id="message"></textarea>
                                </fieldset>
                                <button onClick={(e)=>{formHandler(e)}} className="tf-button btn-effect" type="submit"><span className="boder-fade"></span><span className="effect">{t("send")}</span></button>
                            </div>
                        </div>
                        <div className="col-xl-7  col-md-12">
                            <div className="contact-details" data-aos="fade-left" data-aos-duration="800">
                                <div className="adress wrap-fx">
                                    <div className="location">
                                        <h6>{t("address")}</h6>
                                        <ul>
                                            <li>{t("office-address")}</li>
                                        </ul>
                                    </div>
                                    <div className="mail">
                                        <h6>{t("connection")}</h6>
                                        <ul>
                                            <li>+998905641262</li>
                                            <li>+998940786678</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="flat-map wow fadeIn animated" data-wow-delay="0.3ms" data-wow-duration="1000ms">
                                    <iframe title='map' className="map-content" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d187.11532152643153!2d69.2702619226453!3d41.37744905068475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8d713d916cc7%3A0x2a62c5da421c29ce!2sLindl%20school!5e0!3m2!1sru!2s!4v1672145355487!5m2!1sru!2s" width="1720" height="655" allowFullScreen="" loading="lazy" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Contact;