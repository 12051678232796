import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.scss';
import { useTranslation } from 'react-i18next';

ProjectItem.propTypes = {
    item: PropTypes.object,
};

function ProjectItem(props) {

    const {item} = props;

    const {t} = useTranslation()

    return (
        <div className="img-box">
            <img src={item.img} alt="crybox" />
            <div className="content">
                <Link to="/nft-item">{t(item.title)}</Link>
            </div>
        </div>
    );
}

export default ProjectItem;