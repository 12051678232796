const dataAbout = [
    {
        id: 1,
        title: 'high-speed',
        desc: "about-innobek-2",
        class: 'active'
    },
    {
        id: 2,
        title: 'modern-design',
        desc: "about-innobek-3",
    },
    {
        id: 3,
        title: 'cheap',
        desc: "about-innobek-4",
    },
]

export default dataAbout;