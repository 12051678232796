import React from 'react';
import { Link } from 'react-router-dom';
import dataBlog from '../../assets/fake-data/data-blog';
import Blog from '../../features/blog/home-v2';


function BlogDetails(props) {
    return (
        <div className='post-details'>
            <section className="tf-section detail">
                <div className="container">
                    <div className="row">
                        <article className="article" data-aos="fade-in" data-aos-duration="800">
                            <div className="post p-b23">
                                <div className="image">
                                </div>
                                <h4 className="title">Web saytlar kimlar uchun va qanday turlari mavjud?</h4>                 
                            </div>
                            <div className="post-details">
                                <p>Web sayt - ma'lumotlar jamlangan internet sahifasi hisoblanadi. Web saytlarda tashkilotlar yoki sahxslar o'zlari xohlagan ma'lumotlarini mijozlariga ulashishlari mumkin bo'ladi. Agar, shaxsiy web sayt bo'lsa, u insonni faoliyatlati haqodagi ma'lumotlar, yoki, biror tashkilot uchun bo'lsa, u tashkilot maxsulotlari yoki xizmatlari haqida batafsil ma'lumot berish uchun biz web saytlardan foydalanishimiz mumkin.</p>
                                <p>Hozirgi kunda internet kundan-kunga rivojlanib borayotgani sababli, web saytlarga bo'lgan talablar ham ortiq bormqoda. Sababi, barcha biznes egalari, tashkilotlar uchun ham o'z mijozlariga to'liq ma'lumot berish uchun eng to'g'ri va oson yo'l web sayt hisoblanadi.</p>
                                <div className="image">
                                    <img src={require('../../assets/images/post/post3.png')} alt="" />
                                </div>
                            </div>
                            <div className="content-details">
                                <h6>Keling, endi bu sayt turlariga batafsil to‘xtaylik.</h6>
                                <p>Saytlar haqida gap borar ekan, ular haqida ko‘proq ma’lumot berishga harakat qilamiz. Sayt — bu umumiy tushuncha bo‘lib, uni ko‘rinishi, bajaradigan vazifasiga qarab bir necha turlarga bo‘lish mumkin. Maqola aynan sayt turlariga bag‘ishlanadi.</p>
                                <ul className="list">
                                    <li><svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z" fill="white"/>
                                        </svg>
                                        <span> Vizitka saytlari — bir shaxs yoki biror firma haqida 3, 4 ta sahifadan tashkil topgan, eng sodda ko‘rinishga ega sayt. Bunday saytni tuzib berish ham unchalik katta mablag‘ talab qilmaydi. Firmalar bunday turdagi saytga ega bo‘lishlaridan maqsad, o‘zlari haqida to‘liq ma’lumot berishlari, o‘z xizmatlari, narxlari va bog‘lanish mumkin bo‘lgan ma’lumotlarni internet foydalanuvchilariga e’lon qilishdan iborat.</span>
                                    </li>
                                    <li><svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z" fill="white"/>
                                        </svg>
                                        <span>Korporativ saytlar — to‘liq, murakkab ko‘rinishdagi sayt. Bunday ko‘rinishdagi saytlarda firma haqida to‘liq ma’lumotlar, ularning mahsulotlari haqida(rasmlari bilan birga), narxlari beriladi. Foydalanuvchilar o‘z fikrlarini qoldirishlari, sayt orqali buyurtmalar berishlari ham mumkin bo‘ladi. Har xil turdagi interaktiv xizmatlar ham mavjud bo‘lishi mumkin.</span>
                                    </li>
                                    <li><svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z" fill="white"/>
                                        </svg>
                                        <span>Internet do‘konlar — internet orqali savdo sotiqni yo‘lga qo‘yuvchilar uchun sayt. Bizda bu yo‘nalish endi rivojlanmoqda. Saytda asosan mahsulotlar, ularning narxlari, to‘lovlar haqida ma’lumotlar bo‘ladi. Asosiy sahifada yangi tovarlar rasmlari bilan berib boriladi.</span>
                                    </li>
                                    <li><svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z" fill="white"/>
                                        </svg>
                                        <span>Promo saytlar — ma’lum bir vaqt uchun yaratilgan saytlar. Qandaydir konferensiyalar, ko‘rgazmalar, konsertlar uchun yaratilgan sayt. Bu bayramlar tugagandan so‘ng, sayt yopilishi kerak, lekin ko‘p hollarda unday emas, eski xabarlar bilan sayt ishlashda davom etadi. Bu e’tiborsizlik natijasidir.</span>
                                    </li>
                                </ul>
            
                            </div>
                            <div className="content-details">
                                <h6>Web saytda qanday bo'limlar bo'lishi kerak?</h6>
                                <p className="st2">Veb-saytlar turli xil sahifalardan iborat. Ushbu saytlarda bo'lishi kerak bo'lgan ba'zi sahifalar uchun standart format mavjud. Kichik biznesda, veb-sayt yaratilishi kerak bo'lgan beshta bo'lim mavjud.</p>
                                <p><br /> Bosh sahifa- Butun veb-saytning qisqacha mazmuni bu erda ko'rsatilishi kerak. Bu erda nima qilinishini va nima uchun mijozlar taqdim etilayotgan mahsulot yoki xizmatlarni sotib olishlari kerakligini tushuntirish uchun joy.
                                <br /> <br /> Men haqimda sahifa: foydalanuvchi o'zi bilan kim gaplashayotganini bilishi muhimdir. Ushbu sahifa xushyoqishni shakllantirish va o'quvchilar bilan ishonchni mustahkamlash uchun juda muhimdir.
                                <br /> <br /> Aloqa sahifasi: aloqa muhim ahamiyatga ega. Shu sababli, veb-sahifalar odatda tezkor shaklni yoki hatto elektron pochta yoki pochta manzili kabi ba'zi aloqa ma'lumotlarini o'z ichiga oladi.
                                <br /> <br /> Xizmatlar sahifasi: mijoz nima bilan shug'ullanishi haqida aniq bo'lishi muhimdir. Sahifaga etib borgach, ular nima taqdim etilishini bilishni xohlashadi. Hech qanday shubha tug'ilmasligi uchun sayt ma'murlari kerakli ma'lumotlarni ko'rsatib qo'yishlari kerak.
                                <br /> <br /> Huquqiy sahifalar: Qonunga rioya qilish juda muhimdir. Shu sababli, veb-sahifalarda ma'lumotlar maxfiyligiga oid amaldagi qonunchilik batafsil bayon qilingan sahifalar etishmasligi kerak. </p>
                            </div>
                        </article>         
                    </div>
                </div>
            </section>
            <Blog data={dataBlog} />
        </div>
        
    );
}

export default BlogDetails;