import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

AboutItem.propTypes = {
    item: PropTypes.object,
};

function AboutItem(props) {

    const {item} = props;

    const {t} = useTranslation()

    return (
        <div key={item.id} className={`box-text corner-box ${item.class}`}>
            <div className="h7">{t(item.title)}</div>
            <p>{t(item.desc)}</p>
        </div>
    );
}

export default AboutItem;