import React from 'react';
import { Link } from 'react-router-dom';
import dataBlog from '../../assets/fake-data/data-blog';
import Blog from '../../features/blog/home-v2';


function BlogDetails(props) {
    return (
        <div className='post-details'>
            <section className="tf-section detail">
                <div className="container">
                    <div className="row">
                        <article className="article" data-aos="fade-in" data-aos-duration="800">
                            <div className="post p-b23">
                                <div className="image">
                                </div>
                                <h4 className="title">Internet do'kon: u qanday ishlaydi va nega kerak?</h4>                 
                            </div>
                            <div className="post-details">
                                <p>Internet-do’kon — elektron savdo-sotiq shakli, tovarlarni Internet orqali sotishga mo’ljallangan veb-sayt. Internet-do’kon xaridorlari onlayn tarzda (kompyuter yoki smartfonda) tovarlarni tanlashlari, xarid uchun buyurtmani rasmiylashtirishlari, to’lov va yetkazib berish usullarini tanlashlari (elektron to’lov tizimi orqali to’lashlari ham) mumkin.</p>
                                <p>Internet-do’konda savdo masofadan amalga oshiriladi. Bu holat ayrim tovarlar sotilishiga cheklovlar kiritilishiga sabab bo’ladi. Masalan, O’zbekistonda ham internet-do’konlarda alkogol mahsulotlar, zargarlik buyumlari va ayrim boshqa tovarlar internet orqali erkin sotilishi qonunchilik bilan taqiqlangan yoki cheklangan.</p>
                                <div className="image">
                                    <img src={require('../../assets/images/post/post2.png')} alt="" />
                                </div>
                            </div>
                            <div className="content-details">
                                <h6>Internet do'konda savdo qanday amalga oshiriladi?</h6>
                                <p className="st2">Internet-do’kondagi tovarlar katalog tarzida taqdim etiladi (xuddi oddiy do’kondagi polkalardek), turli kategoriyalarga ajratiladi va tovar fotosurati, narxi, qisqacha ma’lumotlari va boshqa xususiyatlariga ega bo’ladi (xuddi oddiy do’kondagi bo’limlardek). Saytga tashrif buyurgan shaxs (xaridor) tovar xususiyatlari bilan tanishib chiqib, o’ziga keraklisini “Savatcha”ga solish imkoniyatiga ega (oddiy do’kondagi savatchadek). Keyin “Savatcha”ga o’tib, o’zi tanlagan tovarlarni, ularning summasini ko’rishi mumkin. “Savatcha”da tovarlar miqdorini o’zgartirishi, ortiqchasini chiqarib tashlashi, yetkazish usulini tanlashi mumkin. Shundan keyin “Buyurtmani rasmiylashtirish” sahifasida o’zining shaxsiy ma’lumotlarini qoldiradi va to’lov usulini tanlaydi (buyurtma beradi).</p>
                                
                            </div>
                            <div className="content-details">
                                <h6>Internet-do’konning oddiy do’konlarga nisbatan eng asosiy afzalliklari quyidagilar:</h6>
                                <ul className="list">
                                    <li><svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z" fill="white"/>
                                        </svg>
                                        <span>Internet-do’konga kuniga 24 soat va yilning 365 kunida, tushlik payti yoki dam olish, bayram kunlariga qaramasdan, sutkaning xohlagan paytida kirish, tovarlarni tanlash va buyurtma qoldirish mumkin;</span>
                                    </li>
                                    <li><svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z" fill="white"/>
                                        </svg>
                                        <span> Internet-do’konga dunyoning xohlagan joyidagi har qanday xaridor uyidan chiqmagan holda kirishi mumkin;</span>
                                    </li>
                                    <li><svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z" fill="white"/>
                                        </svg>
                                        <span> Internet-do’konga joylahtiriladigan tovarlar hamda xizmatlar turlari va soni cheklanmagan;</span>
                                    </li>
                                    <li><svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z" fill="white"/>
                                        </svg>
                                        <span>Internet-do’kon xaridorlari va sotuvchilari bir-biri bilan oradagi masofadan qat’iy nazar onlayn-chat, elektron pochta orqali va boshqa usullarda xohlagan paytda bog’lanishlari mumkin;</span>
                                    </li>
                                    <li><svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z" fill="white"/>
                                        </svg>
                                        <span>Mobil qurilmalar va internet rivojlanishi xaridor xohlagan joyida turib (ko’chada, hovlida, uyda, mehmonxonada va hokazo) Internet-do’konga kirish tovar sotib olish imkonini beradi;</span>
                                    </li>
                                </ul>
                                <p>Internet-do’kon – bu savdo-sotiq sohasining kelajagi.</p>
                            </div>
                        </article>         
                    </div>
                </div>
            </section>

            <Blog data={dataBlog} />
        </div>
        
    );
}

export default BlogDetails;