const dataWork = [
    {
        id: 1,
        numb: '01',
        title: 'service-1',
        text : "service-1-desc",
        active: 'active'
    },
    {
        id: 2,
        numb: '02',
        title: 'service-2',
        text : "service-2-desc"
    },
    {
        id: 3,
        numb: '03',
        title: "service-3",
        text : "service-3-desc"
    },
    {
        id: 4,
        numb: '04',
        title: "service-4",
        text : "service-4-desc"
    },
]
export default dataWork;