import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.scss'
import { useTranslation } from 'react-i18next';

BlogItem.propTypes = {
    item: PropTypes.object,
};

function BlogItem(props) {
    const {item} = props;

    const {t} = useTranslation()

    return (
        <div className="post-item">
            <div className="wrap">
                <p to="#" className="tag">{t(item.category)}</p>
                <div className="image">
                    <img src={item.img} alt="Crybox" />
                </div>
            </div>
            <div>
            <div style={{padding:"8px 14px"}} className="h7 title"><Link to={item.link}>{t(item.title)}</Link></div>
            <p style={{padding:"8px 14px"}}>{t(item.text)}</p>
            </div>
        </div>
    );
}

export default BlogItem;