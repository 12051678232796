import React from 'react';
import { Link } from 'react-router-dom';
import dataBlog from '../../assets/fake-data/data-blog';
import Blog from '../../features/blog/home-v2';
import { useTranslation } from 'react-i18next';


function BlogDetails(props) {


    const {t} = useTranslation()

    return (
        <div className='post-details'>
            <section className="tf-section detail">
                <div className="container">
                    <div className="row">
                        <article className="article" data-aos="fade-in" data-aos-duration="800">
                            <div className="post p-b23">
                                <div className="image">
                                </div>
                                <h4 className="title">{t("blog-1-heading")}</h4>                 
                            </div>
                            <div className="post-details">
                                <p> {t("blog-1-desc")}</p>
                                <p> {t("blog-1-desc-2")}</p>
                                <div className="image">
                                    <img src={require('../../assets/images/post/post1.png')} alt="" />
                                </div>
                            </div>
                            <div className="content-details">
                                <h6>{t("blog-1-desc-3")}</h6>
                                <p>{t("blog-1-desc-4")}</p>
                                <ul className="list">
                                    <li><svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z" fill="white"/>
                                        </svg>
                                        <span>{t("blog-1-desc-5")}</span>
                                    </li>
                                    <li><svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z" fill="white"/>
                                        </svg>
                                        <span>{t("blog-1-desc-6")}</span>
                                    </li>
                                    <li><svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z" fill="white"/>
                                        </svg>
                                        <span>{t("blog-1-desc-7")}</span>
                                    </li>
                                </ul>
                                <p>{t("blog-1-desc-8")}</p>
                            </div>
                            <div className="content-details">
                                <h6>{t("blog-1-desc-9")}</h6>
                                <p className="st2">{t("blog-1-desc-10")}</p>
                                <p>{t("blog-1-desc-11")}</p>
                                
                            </div>
                        </article>         
                    </div>
                </div>
            </section>

            <Blog data={dataBlog} />
        </div>
        
    );
}

export default BlogDetails;