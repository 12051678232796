import React from 'react';
import PropTypes from 'prop-types';

import { Scrollbar, A11y   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import './styles.scss'
import { useTranslation } from 'react-i18next';

Partner.propTypes = {
    data: PropTypes.array,
};

function Partner(props) {

    const {data} = props;

    const {t} = useTranslation()

    return (
        <section className="tf-section partner">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title" data-aos="fade-up" data-aos-duration="800">
                            <p className="h8 sub-title">{t("partners")}</p>
                            <h4 className="title">{t("clients")}</h4>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="item-parner"  data-aos="fade-up" data-aos-duration="800">
                            {
                                data.slice(0,6).map((item,idx) => (
                                        <div className="image" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 20px'}}>
                                            <img src={item.img} width={'200px'} height={"200px"} alt="Crybox" />
                                        </div>
                                ))
                            }
                        </div>
                        <div className="item-parner"  data-aos="fade-up" data-aos-duration="800">
                            {
                                data.slice(6,12).map((item,idx) => (
                                        <div className="image" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 20px'}}>
                                            <img src={item.img} width={'200px'} height={"200px"} style={{objectFit: 'cover'}} alt="Crybox" />
                                        </div>
                                ))
                            }
                        </div>
                        <div className="item-parner"  data-aos="fade-up" data-aos-duration="800">
                            {
                                data.slice(18,24).map((item,idx) => (
                                        <div className="image" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px 20px', backgroundColor: '#fff'}}>
                                            <img src={item.img} width={'200px'}  height={"200px"} alt="Crybox" />
                                        </div>
                                ))
                            }
                        </div>
                        <div className="item-parner"  data-aos="fade-up" data-aos-duration="800">
                            {
                                data.slice(12,18).map((item,idx) => (
                                        <div className="image" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px 20px', backgroundColor: '#fff'}}>
                                            <img src={item.img} width={'200px'} height={"200px"} alt="Crybox" />
                                        </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Partner;