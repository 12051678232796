import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { useTranslation } from 'react-i18next';

WorkItem.propTypes = {
  item: PropTypes.object,  
};

function WorkItem(props) {

    const {item} = props;

    const {t} = useTranslation()

    return (
        <div style={{minHeight: "300px"}}  className={`box-text st2 corner-box ${item.active}`}>
            <div className="number">{item.numb}</div>
            <div className="h7">{t(item.title)}</div>
            <p>{t(item.text)}</p>
        </div>
    );
}

export default WorkItem;