import img1 from '../images/common/project1.jpg'
import img2 from '../images/common/project2.jpg'
import img3 from '../images/common/project3.jpg'
import img4 from '../images/common/project4.jpg'
import img5 from '../images/common/project5.jpg'
import img6 from '../images/common/1.png'
import img7 from '../images/common/2.png'
import img8 from '../images/common/3.png'
import img9 from '../images/common/4.png'
import img10 from '../images/common/5.png'
import img11 from '../images/common/6.png'
import img12 from '../images/common/1.1.png'
import img13 from '../images/common/1.2.png'
import img14 from '../images/common/7.png'

const dataProject = [
    {
        id: 1,
        title: 'site',
        img: img1,
    },
    {
        id: 2,
        title: 'site',
        img: img2,
    },
    {
        id: 3,
        title: 'mob-app',
        img: img3,
    },
    {
        id: 4,
        title: 'site',
        img: img4,
    },
    {
        id: 5,
        title: 'mob-app',
        img: img5,
    },
    {
        id: 6,
        title: 'site',
        img: img6,
    },
    {
        id: 7,
        title: 'site',
        img: img7,
    },
    {
        id: 8,
        title: 'site',
        img: img8,
    },

    {
        id: 9,
        title: 'crm',
        img: img9,
    },
    {
        id: 10,
        title: 'mob-app',
        img: img10,
    },
    {
        id: 11,
        title: 'site',
        img: img11,
    },
    {
        id: 12,
        title: 'site',
        img: img12,
    },
    {
        id: 13,
        title: 'site',
        img: img13,
    },
    {
        id: 14,
        title: 'site',
        img: img4,
    },
    {
        id: 15,
        title: 'mob-app',
        img: img5,
    },
    {
        id: 16,
        title: 'site',
        img: img1,
    },
    {
        id: 17,
        title: 'site',
        img: img2,
    },
    {
        id: 18,
        title: 'mob-app',
        img: img3,
    },
    {
        id: 19,
        title: 'site',
        img: img4,
    },
    {
        id: 20,
        title: 'mob-app',
        img: img5,
    },
]

export default dataProject;