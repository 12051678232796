import React , {useState} from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
import Button from '../../components/button';
import AboutItem from './about-item';
import { useTranslation } from 'react-i18next';

About.propTypes = {
    data : PropTypes.array,
};

function About(props) {

    const {data} = props;

    const [dataBlock] = useState(
        {
            subtitle : 'about-us',
            title: 'how-innobek',
            desc : 'about-innobek'
        }
    )

    const {t} = useTranslation()

    return (
        <section id='haqimizda' className="tf-section tf-about">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-md-12">
                        <div className="content-about mobie-40" data-aos="fade-up" data-aos-duration="800">
                            <div className="tf-title st2">
                                <p className="h8 sub-title">{t(dataBlock.subtitle)}</p>
                                <h4 className="title">{t(dataBlock.title)}</h4>
                            </div>
                            <p>{t(dataBlock.desc)}</p>
                            <Button title={t("connect")} path="tel:+998909541262"/>
                        </div>
                    </div>
                    <div className="col-xl-7 col-md-12">
                        <div className="wrap-about" data-aos="fade-up" data-aos-duration="800">
                            {
                                data.map(item => (
                                    <AboutItem key={item.id} item={item} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;