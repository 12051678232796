import React, { useState, useEffect } from "react";

import { Link, NavLink, useSearchParams } from "react-router-dom";
import menus from "../../pages/menu";

import "./styles.scss";
import logo from "../../assets/images/logo/logoinno.png";
import Button from "../button";
import { useTranslation } from "react-i18next";

import rus from "../../assets/images/rus.png";
import uz from "../../assets/images/uz.png";

const Header = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 300);
    });
    return () => {
      setScroll({});
    };
  }, []);

  const [menuActive, setMenuActive] = useState(null);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleDropdown = (index) => {
    setActiveIndex(index);
  };

  const { t, i18n } = useTranslation();

  const languages = [
    {
      id: 1,
      img: uz,
      name: "UZ",      
      lName: "uz",
      query: "?lang=uz",
    },
    {
      id: 2,
      img: rus,
      name: "RU",      
      lName: "ru",
      query: "?lang=ru",
    },
  ];

  const [lang, setLang] = useState(languages[0]);

  useEffect(() => {
    if(localStorage.getItem("lang")) {
      
      setLang(languages.find(i => i.lName == localStorage.getItem("lang")))
      if(!window.location.search.includes(`?lang=${localStorage.getItem("lang")}`)) {
        window.location.search = `?lang=${localStorage.getItem("lang")}`
      }
    } else {
      if (window.location.search.includes("?lang=ru")) {
        setLang(languages[1]);
        i18n.changeLanguage("ru");
      } else if(window.location.search.includes("?lang=uz")) {
        setLang(languages[0]);
        i18n.changeLanguage("uz");
      } else {
        setLang(languages[0]);
        i18n.changeLanguage("uz");
        // window.location.search = `?lang=uz`

      }
    }
  }, []);

  const [open, setOpen] = useState(false);

  return (
    <header id="header_main" className={`header ${scroll ? "is-fixed" : ""}`}>
      <div className="container">
        <div id="site-header-inner">
          <div className="header__logo">
            <NavLink to="/">
              <img src={logo} className="navlogo" alt="Crybox" />
            </NavLink>
          </div>
          <nav
            id="main-nav"
            className={`main-nav ${menuActive ? "active" : ""}`}
          >
            <ul id="menu-primary-menu" className="menu">
              {menus.map((data, idx) => (
                <li
                  key={idx}
                  onClick={() => handleDropdown(idx)}
                  className={`menu-item ${
                    data.namesub ? "menu-item-has-children" : ""
                  } ${activeIndex === idx ? "active" : ""}`}
                >
                  <a href={data.links}>{t(data.name)}</a>
                  {data.namesub && (
                    <ul className="sub-menu">
                      {data.namesub.map((submenu) => (
                        <li key={submenu.id} className="menu-item">
                          <NavLink to={submenu.links}>{submenu.sub}</NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <Button title={t("connect")} path="tel:+998909541262" />

          <div  className="lang">
            <button
              onClick={() => {
                setOpen((state) => !state);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                background: "transparent",
                border: "none",
                color: "white",
                fontWeight: "600"
              }}
              className=""
            >
              <img
                style={{ width: "40px", height: "30px", objectFit: "cover" }}
                src={lang.img}
                alt=""
                className=""
                />
                <span>{lang.name}</span>
            </button>
            <div
              style={{
                position: "absolute",
                top: "100%",
                left: "0",
                display: open ? "block" : "none",
              }}
              className=""
            >
              {languages.map((item) => {
                if (item.id !== lang.id) {
                  return (
                    <button
                      onClick={() => {
                        setOpen((state) => !state);
                        setLang(item);
                        i18n.changeLanguage(item.lName);
                        window.location.search = item.query;
                        localStorage.setItem("lang", item.lName)
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        background: "transparent",
                        border: "none",
                        color: "white",
                        fontWeight: "600"
                      }}
                      className=""
                    >
                      <img
                        style={{
                          width: "40px",
                          height: "30px",
                          objectFit: "cover",
                        }}
                        src={item.img}
                        alt=""
                        className=""
                        />
                        <span>{item.name}</span>
                    </button>
                  );
                }
              })}
            </div>
          </div>

          <div
            className={`mobile-button ${menuActive ? "active" : ""}`}
            onClick={handleMenuActive}
          >
            <span></span>
          </div>
          {/* <button onClick={() => {i18n.changeLanguage("ru")}} >ru</button>
          <button onClick={() => {i18n.changeLanguage("uz")}} >uz</button>
          <p className="">{t("text")}</p> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
