// import bgImg1 from "../images/background/bg1roadmap.png";
import img1 from "../images/background/mobile.png"; 
import img2 from "../images/background/website.png";
import bg from "../images/background/bg-main.png"

const dataSlider = [
  {
    id: 1,
    title: "hero-site",
    desc: "hero-site-desc",
    bgImg: bg,
    img: img2,
  },
  {
    id: 2,
    title: "hero-mobile",
    desc: "hero-mobile-desc",
    bgImg: bg,
    img: img1,
  },
  {
    id: 3,
    title: "hero-innobek",
    span: "uzbek",
    desc: "hero-innobek-desc",
    bgImg: bg,
    classAction: "three",
  },
  // {
  //   id: 4,
  //   title: "CYbox nft collectionS for everyone",
  //   desc: "Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.",
  //   bgImg: bgImg1,
  //   img: img1,
  // },
  // {
  //   id: 5,
  //   title: "CYbox nft collectionS for everyone",
  //   desc: "Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.",
  //   bgImg: bgImg2,
  //   classAction: "two",
  // },
];

export default dataSlider;
