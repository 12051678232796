const dataFaq = [
    {
        id: 1,
        title: 'faq-1',
        content: "faq-1-answer",
        show: 'show'
    },
    {
        id: 2,
        title: 'faq-2',
        content: "faq-2-answer",
    },
    {
        id: 3,
        title: 'faq-3',
        content: "faq-3-answer",
    },
    {
        id: 4,
        title: 'faq-4',
        content: "faq-4-answer",
    },
]

export default dataFaq;